<script>
export default {
  name: 'mytable',
  props: {
    columns: {
      type: Array,
      defalut() {
        return []
      }
    },
    dataSource: {
      type: Array,
      default() {
        return []
      }
    },
    pagination: {
      type: [Object, Boolean],
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    scroll: {
      type: [Object, null],
      default() {
        return null
      }
    },
    rowKey: {
      type: Function,
      default(record) {
        return record.id
      }
    },
    rowSelection: {
      type: [Object, null],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      newScroll: {}
    }
  },
  render() {
    const on = {
      ...this.$listeners
    }
    let props = {
      ...this.$props
    }
    if (this.rowSelection) {
      props = { ...props, ...{ rowSelection: this.rowSelection } }
    } else {
      delete props.rowSelection
    }
    if (this.scroll) {
      props = { ...props, ...{ scroll: this.newScroll } }
    } else {
      delete props.scroll
    }
    const slots = Object.keys(this.$slots).map((slot) => {
      return <template slot={slot}>{this.$slots[slot]}</template>
    })
    const table = (
      <a-table props={props} scopedSlots={this.$scopedSlots} on={on}>
        {slots}
      </a-table>
    )
    return (
      <div class="table-wrap" ref="table">
        {table}
      </div>
    )
  },
  watch: {
    dataSource() {
      if (this.scroll) {
        this.newScroll.x = this.scroll.x
        if (this.scroll.y) {
          const tableWrap = this.$refs.table
          const y = tableWrap.offsetHeight + ''
          const n = 150 + Number(y.charAt(y.length - 1))
          this.newScroll.y = y - n
        }
      }
    }
  }
}
</script>
