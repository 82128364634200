<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN' // 中文语言包
export default {
  data() {
    return {
      locale: zhCN
    }
  },
  created() {
    const token = window.sessionStorage.getItem('token')
    this.$store.commit('setToken', token)

    let qiniuData = window.sessionStorage.getItem('qiniuData')
    if (qiniuData) {
      qiniuData = JSON.parse(qiniuData)
      this.$store.commit('setQiniuData', qiniuData)
    }
  }
}
</script>
<style>
body {
  font-family: 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
    Arial !important;
}
</style>
<style lang="less">
@import '~ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
// @import './assets/less/reset.less'; // 用于覆盖上面定义的变量
</style>
