import store from '../store'
function checkArray(key) {
  const i = store.state.pageButtons.findIndex((e) => e.value === key)
  if (i === -1) {
    return false // 无权限
  } else {
    return true // 有权限
  }
}
export default {
  install(Vue) {
    // 1：el指绑定的dom元素
    // 2：binding一个对象，包含指令的很多信息, 使用时可以绑定的值
    // 3：vnodeVUE编译生成的虚拟节点
    Vue.directive('permission', {
      bind() {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted(el, binding) {
        let permission = binding.value // 获取到 v-permission的值
        if (permission) {
          let hasPermission = checkArray(permission)
          if (!hasPermission) {
            // 没有权限 移除Dom元素
            el.parentNode && el.parentNode.removeChild(el)
          }
        }
      }, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update() {}, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
  }
}

// 使用
/*
 *  显示
 *  <button v-permission="1">权限按钮1</button>
 *  不显示
 *  <button v-permission="10">权限按钮2</button>
 */
