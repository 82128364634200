import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index/index.vue'

Vue.use(VueRouter)
/*
 * clickNum: Number 面包屑点击后退层数
 * pageLevel: 0  // 页面级数（用于判断前进后退）
 * keepAlive: Boolean  是否缓存页面（设为true，则缓存当前页面，切换页面不刷新）
 */
const children = [
  {
    path: '/personalInfo',
    name: 'personalInfo',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['资料/钱包'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "personal-info" */ '../views/main/personal-info.vue'
      )
  },
  {
    path: '/withdrawalHistory',
    name: 'withdrawalHistory',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['提现记录'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "withdrawal-history" */ '../views/main/withdrawal-history.vue'
      )
  },
  {
    path: '/withdrawInfo',
    name: 'withdrawInfo',
    meta: {
      fromPath: [],
      current: ['提现详情'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "withdraw-info" */ '../views/main/withdrawal-approve-accounting-info.vue'
      )
  },
  {
    path: '/statistic',
    name: 'statistic',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['数据分析'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "statistic" */ '../views/main/statistic.vue')
  },
  {
    path: '/orderItem',
    name: 'orderItem',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // }
      ],
      current: ['单品订单管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "order-item" */ '../views/main/order-item.vue'
      )
  },
  {
    path: '/orderItemInfo',
    name: 'orderItemInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // },
        // {
        //   text: '单品订单管理',
        //   disabled: false
        // }
      ],
      current: ['单品订单详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "order-item-info" */ '../views/main/order-item-info.vue'
      )
  },
  {
    path: '/activityOrder',
    name: 'activityOrder',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // }
      ],
      current: ['活动订单管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "activity-order" */ '../views/main/activity-order.vue'
      )
  },
  {
    path: '/activityOrderInfo',
    name: 'activityOrderInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // },
        // {
        //   text: '单品订单管理',
        //   disabled: false
        // }
      ],
      current: ['活动订单详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "activity-order-info" */ '../views/main/activity-order-info.vue'
      )
  },
  {
    path: '/orderGoods',
    name: 'orderGoods',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['购物商城'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "order-goods" */ '../views/main/order-goods.vue'
      )
  },
  {
    path: '/orderGoodsInfo',
    name: 'orderGoodsInfo',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['购物商城详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "order-goods-info" */ '../views/main/order-goods-info.vue'
      )
  },
  {
    path: '/orderSurroundings',
    name: 'orderSurroundings',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['环境治理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "order-surroundings" */ '../views/main/order-surroundings.vue'
      )
  },
  {
    path: '/orderSurroundingsInfo',
    name: 'orderSurroundingsInfo',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['环境治理详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "order-surroundings-info" */ '../views/main/order-surroundings-info.vue'
      )
  },
  {
    path: '/carbeauty',
    name: 'carbeauty',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // }
      ],
      current: ['汽车美容'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "carbeauty" */ '../views/main/carbeauty.vue'
      )
  },
  {
    path: '/carbeautyDetail',
    name: 'carbeautyDetail',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '订单管理',
        //   disabled: true
        // }
      ],
      current: ['汽车美容'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "carbeautyDetail" */ '../views/main/carbeautyDetail.vue'
      )
  },
  {
    path: '/userInfoCard',
    name: 'userInfoCard',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '注册用户管理',
        //   disabled: false
        // }
      ],
      current: ['注册用户详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "user-info-card" */ '../views/main/user-info-card.vue')
  },
  {
    path: '/apprentice',
    name: 'apprentice',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['实习学徒管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice" */ '../views/main/apprentice.vue'
      )
  },
  {
    path: '/apprenticeAdd',
    name: 'apprenticeAdd',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // }
      ],
      current: ['录入学徒'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice-add" */ '../views/main/apprentice-add.vue'
      )
  },
  {
    path: '/apprenticeAssessment',
    name: 'apprenticeAssessment',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // }
      ],
      current: ['考核转正'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice-assessment" */ '../views/main/apprentice-assessment.vue'
      )
  },
  {
    path: '/apprenticeInfo',
    name: 'apprenticeInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // }
      ],
      current: ['学徒详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice-info" */ '../views/main/apprentice-info.vue'
      )
  },
  {
    path: '/apprenticeTraining',
    name: 'apprenticeTraining',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // }
      ],
      current: ['培训管理'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice-training" */ '../views/main/apprentice-training.vue'
      )
  },
  {
    path: '/apprenticeTurn',
    name: 'apprenticeTurn',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // },
        // {
        //   text: '实习学徒管理',
        //   disabled: false
        // }
      ],
      current: ['转编师傅'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "apprentice-turn" */ '../views/main/apprentice-turn.vue'
      )
  },
  {
    path: '/master',
    name: 'master',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['作业师傅管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "master" */ '../views/main/master.vue')
  },
  {
    path: '/masterInfo',
    name: 'masterInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '作业师傅管理',
        //   disabled: false
        // }
      ],
      current: ['作业师傅详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "master-info" */ '../views/main/master-info.vue'
      )
  },
  {
    path: '/lector',
    name: 'lector',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['培训讲师管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "lector" */ '../views/main/lector.vue')
  },
  {
    path: '/lectorInfo',
    name: 'lectorInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '培训讲师管理',
        //   disabled: false
        // }
      ],
      current: ['理论讲师详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "lector-info" */ '../views/main/lector-info.vue'
      )
  },
  {
    path: '/trainingInfo',
    name: 'trainingInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '培训讲师管理',
        //   disabled: false
        // }
      ],
      current: ['实操讲师详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "lector-info" */ '../views/main/lector-info.vue'
      )
  },
  {
    path: '/lectorAdd',
    name: 'lectorAdd',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '培训讲师管理',
        //   disabled: false
        // }
      ],
      current: ['录入理论讲师'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "lector-add" */ '../views/main/lector-add.vue'
      )
  },
  {
    path: '/trainingAdd',
    name: 'trainingAdd',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '培训讲师管理',
        //   disabled: false
        // }
      ],
      current: ['录入实操讲师'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "lector-add" */ '../views/main/lector-add.vue'
      )
  },
  {
    path: '/agentEnterprise',
    name: 'agentEnterprise',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['代理企业管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "agent-enterprise" */ '../views/main/agent-enterprise.vue'
      )
  },
  {
    path: '/agentEnterpriseInfo',
    name: 'agentEnterpriseInfo',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['代理企业详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "agent-enterprise-info" */ '../views/main/agent-enterprise-info.vue'
      )
  },
  {
    path: '/agentEnterpriseBindArea',
    name: 'agentEnterpriseBindArea',
    meta: {
      buttons: [],
      fromPath: [],
      current: ['关联地区'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "agent-enterprise-bind-area" */ '../views/main/agent-enterprise-bind-area.vue'
      )
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['平台协议管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "agreement" */ '../views/main/agreement.vue')
  },
  {
    path: '/agreementAdd',
    name: 'agreementAdd',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // },
        // {
        //   text: '行政管理中心',
        //   disabled: true
        // }
      ],
      current: ['平台协议详情'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "agreement-add" */ '../views/main/agreement-add.vue'
      )
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['注册用户管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/main/user.vue')
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      fromPath: [],
      current: ['门店'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "store" */ '../views/main/store.vue'
      )
  },
  {
    path: '/storeInfo',
    name: 'storeInfo',
    meta: {
      fromPath: [],
      current: ['门店详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "storeInfo" */ '../views/main/storeInfo.vue'
      )
  },
  {
    path: '/storeAdd',
    name: 'storeAdd',
    meta: {
      fromPath: [],
      current: ['新增门店'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "storeAdd" */ '../views/main/storeAdd.vue'
      )
  },
  {
    path: '/community',
    name: 'community',
    meta: {
      fromPath: [],
      current: ['社区管理'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "community" */ '../views/main/community.vue'
      )
  },
  {
    path: '/communityInfo',
    name: 'communityInfo',
    meta: {
      fromPath: [],
      current: ['社区详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "communityInfo" */ '../views/main/communityInfo.vue'
      )
  },
  {
    path: '/communityAdd',
    name: 'communityAdd',
    meta: {
      fromPath: [],
      current: ['新增社区'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "communityAdd" */ '../views/main/communityAdd.vue'
      )
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '注册用户管理',
        //   disabled: false
        // }
      ],
      current: ['注册用户详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "user-info" */ '../views/main/user-info.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['单品业务管理'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "service" */ '../views/main/service.vue')
  },
  {
    path: '/serviceAddress',
    name: 'serviceAddress',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '业务',
        //   disabled: true
        // }
      ],
      current: ['服务地址栅栏'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "service-address" */ '../views/main/service-address.vue'
      )
  },
  {
    path: '/serviceAdd',
    name: 'serviceAdd',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '单品业务管理',
        //   disabled: false
        // }
      ],
      current: ['服务类型管理'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "service-add" */ '../views/main/service-add.vue'
      )
  },
  {
    path: '/serviceItems',
    name: 'serviceItems',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '单品业务管理',
        //   disabled: false
        // }
      ],
      current: ['服务项目/家电管理'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "service-items" */ '../views/main/service-items.vue'
      )
  },
  {
    path: '/serviceEdit',
    name: 'serviceEdit',
    meta: {
      buttons: [],
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '单品业务管理',
        //   disabled: false
        // },
        // {
        //   text: '服务项目/家电管理',
        //   disabled: false
        // }
      ],
      current: ['添加服务项目/家电设备'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "service-edit" */ '../views/main/service-edit.vue'
      )
  },
  {
    path: '/publicData',
    name: 'publicData',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['公共源数据设置'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "public-data" */ '../views/main/public-data.vue'
      )
  },
  {
    path: '/publicServiceItems',
    name: 'publicServiceItems',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '公共源数据设置',
        //   disabled: false
        // }
      ],
      current: ['服务类型管理'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "public-service-items" */ '../views/main/public-service-items.vue'
      )
  },
  {
    path: '/publicServiceAdd',
    name: 'publicServiceAdd',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '公共源数据设置',
        //   disabled: false
        // },
        // {
        //   text: '服务类型管理',
        //   disabled: false
        // }
      ],
      current: ['新增服务类型'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "public-service-add" */ '../views/main/public-service-add.vue'
      )
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['站内/外通知'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(/* webpackChunkName: "notice" */ '../views/main/notice.vue')
  },
  {
    path: '/noticeAdd',
    name: 'noticeAdd',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['新增公告栏通知'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "notice-add" */ '../views/main/notice-add.vue'
      )
  },
  {
    path: '/noticeEdit',
    name: 'noticeEdit',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['编辑公告栏通知'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "notice-edit" */ '../views/main/notice-add.vue'
      )
  },
  {
    path: '/noticeTriggerUser',
    name: 'noticeTriggerUser',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['触达人员设置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "notice-trigger-user" */ '../views/main/notice-trigger-user.vue'
      )
  },
  {
    path: '/andingPageData',
    name: 'andingPageData',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['落地页数据'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "anding-page-data" */ '../views/main/anding-page-data.vue'
      )
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['支线业务（活动）'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "activity" */ '../views/main/activity.vue')
  },
  {
    path: '/activityInfo',
    name: 'activityInfo',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['活动详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "activity-info" */ '../views/main/activity-info.vue'
      )
  },
  {
    path: '/channelBusiness',
    name: 'channelBusiness',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // }
      ],
      current: ['渠道商'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-business" */ '../views/main/channel-business.vue'
      )
  },
  {
    path: '/channelBusinessInfo',
    name: 'channelBusinessInfo',
    meta: {
      fromPath: [],
      current: ['渠道商详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-business-info" */ '../views/main/channel-business-info.vue'
      )
  },
  {
    path: '/channelBusinessAdd',
    name: 'channelBusinessAdd',
    meta: {
      fromPath: [],
      current: ['新增渠道商'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-business-add" */ '../views/main/channel-business-add.vue'
      )
  },
  {
    path: '/channelTerminal',
    name: 'channelTerminal',
    meta: {
      fromPath: [],
      current: ['渠道开发的终端'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-terminal" */ '../views/main/channel-terminal.vue'
      )
  },
  {
    path: '/channelTerminalInfo',
    name: 'channelTerminalInfo',
    meta: {
      fromPath: [],
      current: ['渠道终端详情'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-terminal-info" */ '../views/main/channel-terminal-info.vue'
      )
  },
  {
    path: '/channelTerminalAdd',
    name: 'channelTerminalAdd',
    meta: {
      fromPath: [],
      current: ['新增渠道终端'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "channel-terminal-add" */ '../views/main/channel-terminal-add.vue'
      )
  },
  {
    path: '/clientUser',
    name: 'clientUser',
    meta: {
      fromPath: [
        // {
        //   text: '客户端管理',
        //   disabled: true
        // }
      ],
      current: ['用户端管理'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "client-user" */ '../views/main/client-user.vue'
      )
  },
  {
    path: '/clientUserTabbarIcon',
    name: 'clientUserTabbarIcon',
    meta: {
      fromPath: [
        // {
        //   text: '客户端管理',
        //   disabled: true
        // },
        // {
        //   text: '用户端管理',
        //   disabled: true
        // }
      ],
      current: ['编辑图标'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "client-user-tabbar-icon" */ '../views/main/client-user-tabbar-icon.vue'
      )
  },
  {
    path: '/clientMaster',
    name: 'clientMaster',
    meta: {
      fromPath: [
        // {
        //   text: '客户端管理',
        //   disabled: true
        // }
      ],
      current: ['师傅端管理'],
      pageLevel: 0,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "client-master" */ '../views/main/client-master.vue'
      )
  },
  {
    path: '/clientMasterTabbarIcon',
    name: 'clientMasterTabbarIcon',
    meta: {
      fromPath: [
        // {
        //   text: '客户端管理',
        //   disabled: true
        // },
        // {
        //   text: '师傅端管理',
        //   disabled: true
        // }
      ],
      current: ['编辑图标'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "client-master-tabbar-icon" */ '../views/main/client-master-tabbar-icon.vue'
      )
  },
  {
    path: '/orderItemAccounts',
    name: 'orderItemAccounts',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // }
      ],
      current: ['单品订单账目'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "order-item-accounts" */ '../views/main/order-item-accounts.vue'
      )
  },
  {
    path: '/orderItemAccountsInfo',
    name: 'orderItemAccountsInfo',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // },
        // {
        //   text: '单品订单账目',
        //   disabled: false
        // }
      ],
      current: ['订单详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "order-item-accounts-info" */ '../views/main/order-item-accounts-info.vue'
      )
  },
  {
    path: '/withdrawalApproveAccounting',
    name: 'withdrawalApproveAccounting',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // }
      ],
      current: ['平台提现审批'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "withdrawal-approve-accounting" */ '../views/main/withdrawal-approve-accounting.vue'
      )
  },
  {
    path: '/withdrawalApproveAccountingInfo',
    name: 'withdrawalApproveAccountingInfo',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // },
        // {
        //   text: '平台提现审批',
        //   disabled: false
        // }
      ],
      current: ['提现详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "withdrawal-approve-accounting-info" */ '../views/main/withdrawal-approve-accounting-info.vue'
      )
  },
  {
    path: '/withdrawalApproveCashier',
    name: 'withdrawalApproveCashier',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // }
      ],
      current: ['出纳管理'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "withdrawal-approve-cashier" */ '../views/main/withdrawal-approve-cashier.vue'
      )
  },
  {
    path: '/withdrawalApproveCashierInfo',
    name: 'withdrawalApproveCashierInfo',
    meta: {
      fromPath: [
        // {
        //   text: '财务中心',
        //   disabled: true
        // },
        // {
        //   text: '出纳管理',
        //   disabled: false
        // }
      ],
      current: ['提现详情'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "withdrawal-approve-cashier-info" */ '../views/main/withdrawal-approve-cashier-info.vue'
      )
  },
  {
    path: '/voucher',
    name: 'voucher',
    meta: {
      fromPath: [],
      current: ['代金券'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '../views/main/voucher.vue')
  },
  {
    path: '/voucherIssue',
    name: 'voucherIssue',
    meta: {
      fromPath: [],
      current: ['发放代金券'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "voucher-issue" */ '../views/main/voucher-issue.vue'
      )
  },
  {
    path: '/publicGoods',
    name: 'publicGoods',
    meta: {
      fromPath: [],
      current: ['商城'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '../views/main/public-goods.vue')
  },
  {
    path: '/publicGoodsAdd',
    name: 'publicGoodsAdd',
    meta: {
      fromPath: [],
      current: ['新增商品'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '../views/main/public-goods-add.vue')
  },
  {
    path: '/publicGoods',
    name: 'publicGoods',
    meta: {
      fromPath: [],
      current: ['购物商城'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '../views/main/public-surroundings.vue')
  },
  {
    path: '/publicGoodsAdd',
    name: 'publicGoodsAdd',
    meta: {
      fromPath: [],
      current: ['新增商品'],
      pageLevel: 0,
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '../views/main/public-surroundings-add.vue')
  },
  {
    path: '/publicSurroundings',
    name: 'publicSurroundings',
    meta: {
      fromPath: [
        // {
        //   text: '运营中心',
        //   disabled: true
        // },
        // {
        //   text: '公共源数据设置',
        //   disabled: false
        // }
      ],
      current: ['环境治理'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "public-surroundings" */ '../views/main/public-surroundings.vue'
      )
  },
  {
    path: '/publicSurroundingsAdd',
    name: 'publicSurroundingsAdd',
    meta: {
      fromPath: [],
      current: ['新增服务项目'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "public-surroundings-add" */ '../views/main/public-surroundings-add.vue'
      )
  },
  {
    path: '/fowller',
    name: 'fowller',
    meta: {
      fromPath: [],
      current: ['监工管理'],
      pageLevel: 2,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "fowller" */ '../views/main/fowller.vue'
      )
  },
  {
    path: '/fowllerAdd',
    name: 'fowllerAdd',
    meta: {
      fromPath: [],
      current: ['新增渠道商'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "fowller-add" */ '../views/main/fowller-add.vue'
      )
  },
  {
    path: '/fowllerInfo',
    name: 'fowllerInfo',
    meta: {
      fromPath: [],
      current: ['新增渠道商'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "fowllerInfo" */ '../views/main/fowllerInfo.vue'
      )
  },
  {
    path: '/Supervisor',
    name: 'Supervisor',
    meta: {
      fromPath: [],
      current: ['渠道经理业务比例配置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "fowllerInfo" */ '../views/main/Supervisor.vue'
      )
  },
  {
    path: '/config',
    name: 'config',
    meta: {
      fromPath: [],
      current: ['服务比例配置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../views/main/config.vue'
      )
  },
  {
    path: '/businessConfigInfo',
    name: 'businessConfigInfo',
    meta: {
      fromPath: [],
      current: ['服务比例配置'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "businessConfigInfo" */ '../views/main/businessConfigInfo.vue'
      )
  },
  {
    path: '/publicAreaId',
    name: 'publicAreaId',
    meta: {
      fromPath: [],
      current: ['省市区'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "businessConfigInfo" */ '../views/main/public-areaId.vue'
      )
  },
  {
    path: '/publicAreaIdAdd',
    name: 'publicAreaIdAdd',
    meta: {
      fromPath: [],
      current: ['设备类型'],
      pageLevel: 1,
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "businessConfigInfo" */ '../views/main/public-areaId-add.vue'
      )
  },
]

let permission = window.sessionStorage.getItem('permission')
if (permission) {
  permission = JSON.parse(permission)
  permission.forEach((e) => {
    // 查找父级以及追加父级标题

    if (e.parent_id) {
      const forFn = function (val) {
        permission.forEach((item) => {
          if (item.id === val.parent_id) {
            val.fromPath = item.name
          }
        })
      }
      forFn(e)
    }
  })
  children.forEach((e) => {
    const item = permission.find((f) => f.url === e.path)

    if (item) {
      // 添加上级页面标题
      e.meta.fromPath.push({
        text: item.fromPath,
        disabled: item.visible
      })
      // 添加当前页标题
      e.meta.current = [item.name]
      e.meta.buttons = item.buttons
    }
  })
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: children
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/index/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
