import axios from '../axios/api'
import state from '../store/state'
;(function versionUpdate() {
  const data = {
    value: state.version
  }
  console.log("data",data)
  axios.versionUpdate(data).then((res) => {
    if (!res.data.data) {
      // 刷新
      window.location.reload()
    }
  })
})()
