/**
 * 接口列表
 */

import base from './base' // 导入接口域名列表
import axios from './http' // 导入http中创建的axios实例
const api = {
  versionUpdate(params) {
    return axios.post(`${base.main}admin/Versions/Version`, params)
  },
  wechatLogin() {
    return axios.post(`${base.main}admin/login/needData`)
  },
  getAccount(params) {
    // 获取账号列表
    return axios.post(`${base.main}admin/login/getOpenid`, params)
  },
  getSpreadOrderList(params) {
    // 获取业务员后面订单列表
    return axios.post(`${base.main}admin/Community/getSpreadOrderList`, params)
  },
  getUserInfo() {
    // 获取账号信息
    return axios.post(`${base.main}admin/admin/getTokenAllData`)
  },
  login(params) {
    // 账号登录
    return axios.post(`${base.main}admin/login/login`, params)
  },
  getApprentice(params) {
    // 获取学徒列表
    return axios.post(`${base.main}admin/Apprentice/index`, params)
  },
  getApprenticeInfo(params) {
    // 获取学徒详情
    return axios.post(`${base.main}admin/Apprentice/readApprentice`, params)
  },
  getApprenticeTraining(params) {
    // 获取学徒培训信息
    return axios.post(`${base.main}admin/Apprentice/train`, params)
  },
  getLectors() {
    // 获取学徒理论讲师列表（选择用）
    return axios.post(`${base.main}admin/Apprentice/lectorData`)
  },
  getTrainings() {
    // 获取实操讲师列表（选择用）
    return axios.post(`${base.main}admin/Apprentice/trainData`)
  },
  getVisitisers() {
    // 获取带训师傅列表（选择用）
    return axios.post(`${base.main}admin/Apprentice/visitiservicerData`)
  },
  changeApprenticeMaster(params) {
    // 编辑学徒的师傅信息
    return axios.post(`${base.main}admin/Apprentice/setTrain`, params)
  },
  apprenticeTurnMaster(params) {
    // 转编师傅
    return axios.post(`${base.main}admin/Apprentice/apply`, params)
  },
  getStarData(params) {
    // 获取师傅星级源数据
    return axios.post(`${base.main}admin/Servicer/bcLevelData`, params)
  },
  apprenticeTrainFinish(params) {
    // 学徒培训完成
    return axios.post(`${base.main}admin/Apprentice/setTraingStatus`, params)
  },
  setApprenticeStatus(params) {
    // 设置学徒状态
    return axios.post(`${base.main}admin/Apprentice/setApprentice`, params)
  },
  addApprentice(params) {
    // 录入学徒
    return axios.post(`${base.main}admin/Apprentice/createApprentice`, params)
  },
  setApprenticeAssessment(params) {
    // 学徒考核转正
    return axios.post(`${base.main}admin/Servicer/exampleApprentice`, params)
  },
  getLector(params) {
    // 获取培训理论讲师列表
    return axios.post(`${base.main}admin/Lector/lector`, params)
  },
  getTraining(params) {
    // 获取培训实操讲师列表
    return axios.post(`${base.main}admin/Lector/training`, params)
  },
  getLectorInfo(params) {
    // 获取培训理论讲师详情
    return axios.post(`${base.main}admin/Lector/readLector`, params)
  },
  getTrainingInfo(params) {
    // 获取培训实操讲师详情
    return axios.post(`${base.main}admin/Lector/readTraining`, params)
  },
  addLector(params) {
    // 录入培训讲师
    return axios.post(`${base.main}admin/Lector/createlector`, params)
  },
  getLectorBindWX(params) {
    // 获取讲师绑定微信链接
    return axios.post(`${base.main}admin/Bind/need`, params)
  },
  lectorBindWX(params) {
    // 讲师绑定微信
    return axios.post(`${base.main}admin/Bind/bindOpenid`, params)
  },
  getMaster(params) {
    // 获取师傅列表
    return axios.post(`${base.main}admin/Servicer/index`, params)
  },
  getMasterInfo(params) {
    // 获取师傅详情
    return axios.post(`${base.main}admin/Servicer/read`, params)
  },
  masterTranslate(params) {
    // 服务师傅转培训师傅
    return axios.post(`${base.main}admin/Servicer/setVis`, params)
  },
  changeMasterRate(params) {
    // 设置师傅星级
    return axios.post(`${base.main}admin/Servicer/setBclevel`, params)
  },
  getSetServicerRate(params) {
    // 设置私人比例
    return axios.post(`${base.main}admin/Servicer/setServicerRate`, params)
  },
  getAgentEnterprise(params) {
    // 获取代理企业列表
    return axios.post(`${base.main}admin/Proxy/index`, params)
  },
  getAgentEnterpriseInfo(params) {
    // 获取代理企业详情
    return axios.post(`${base.main}admin/Proxy/read`, params)
  },
  getAgentEnterpriseArea: () =>
    // 获取区域可绑定区域（企业绑定区域用）
    new Promise((resolve) => {
      axios.post(`${base.main}admin/proxy/area`).then((res) => {
        if (res.status === 200) {
          const rows = res.data.data
          repeat(rows)
        }
      })
      function repeat(arr) {
        // 重组数组
        const treeArr = arr.map((e) => {
          return {
            id: e.id,
            name: e.name,
            parent_id: e.parent_id,
            disabled: e.pareaId === null ? false : true
          }
        })
        format(treeArr)
      }
      function format(arr) {
        // 格式化列表
        const array = {}
        const returnArr = []
        arr.forEach((item) => {
          array[item.id] = item
        })
        arr.forEach((item) => {
          if (item.parent_id !== 0) {
            ;(
              array[item.parent_id].children ||
              (array[item.parent_id].children = [])
            ).push(item)
          } else {
            returnArr.push(item)
          }
        })
        resolve({
          tiled: arr,
          nested: returnArr
        })
      }
    }),
  getAgentEnterpriseBindWXLink(params) {
    // 获取代理企业绑定微信二维码（钱包）
    return axios.post(`${base.main}admin/Proxy/needaccoutn`, params)
  },
  getAgentEnterpriseBindWX(params) {
    // 获取代理企业微信信息
    return axios.post(`${base.main}admin/Proxy/bindAccount`, params)
  },
  agentEnterpriseBindWX(params) {
    // 代理企业绑定微信（钱包）
    return axios.post(`${base.main}admin/Proxy/bindProxyAccount`, params)
  },
  addAgentEnterprise(params) {
    // 录入代理企业
    return axios.post(`${base.main}admin/Proxy/create`, params)
  },
  agentEnterpriseBindArea(params) {
    // 代理企业绑定地区
    return axios.post(`${base.main}admin/Proxy/bindArea`, params)
  },
  agentEnterpriseAddAccount(params) {
    // 代理企业新增登录账户
    return axios.post(`${base.main}admin/Proxy/createMiddleProxy`, params)
  },
  getAgentEnterpriseAccountBindWXLink(params) {
    // 获取代理企业账户绑定微信二维码（账号）
    return axios.post(`${base.main}admin/Proxy/need`, params)
  },
  agentEnterpriseAccountBindWX(params) {
    // 代理企业账户绑定微信（账号）
    return axios.post(`${base.main}admin/Proxy/bindOpenid`, params)
  },
  getAgreement(params) {
    // 获取协议列表
    return axios.post(`${base.main}admin/Legal/index`, params)
  },
  getAgreementInfo(params) {
    // 获取协议详情
    return axios.post(`${base.main}admin/Legal/read`, params)
  },
  changeAgreement(params) {
    // 更改协议
    return axios.post(`${base.main}admin/Legal/insert`, params)
  },
  getArea: () =>
    // 获取区域列表
    new Promise((resolve) => {
      axios.post(`${base.main}admin/servicer/area`).then((res) => {
        if (res.status === 200) {
          const rows = res.data.data
          repeat(rows)
        }
      })
      function repeat(arr) {
        // 重组数组
        const treeArr = arr.map((e) => {
          return {
            id: e.id,
            name: e.name,
            parent_id: e.parent_id
          }
        })
        format(treeArr)
      }
      function format(arr) {
        // 格式化列表
        const array = {}
        const returnArr = []
        arr.forEach((item) => {
          array[item.id] = item
        })
        arr.forEach((item) => {
          if (item.parent_id !== 0) {
            ;(
              array[item.parent_id].children ||
              (array[item.parent_id].children = [])
            ).push(item)
          } else {
            returnArr.push(item)
          }
        })
        resolve({
          tiled: arr,
          nested: returnArr
        })
      }
    }),
  getUsers(params) {
    // 获取用户列表
    return axios.post(`${base.main}admin/member/index`, params)
  },
  getUsersInfo(params) {
    // 获取用户详情
    return axios.post(`${base.main}admin/member/read`, params)
  },
  getUsersOrder(params) {
    // 获取用户订单列表
    return axios.post(`${base.main}admin/member/findUserOrder`, params)
  },
  getPunlicService(params) {
    // 获取公共数据源服务类型
    return axios.post(`${base.main}admin/Serving/CateIndex`, params)
  },
  getPublicServiceItems(params) {
    // 获取公共数据服务项目
    return axios.post(`${base.main}admin/Serving/findCateServing`, params)
  },
  getPublicServiceItemsInfo(params) {
    // 获取公共数据服务项目详情
    return axios.post(`${base.main}admin/Serving/servingInfo`, params)
  },
  getPublicServiceItemsEquipment(params) {
    // 获取公共数据服务项目设备
    return axios.post(`${base.main}admin/Serving/findServingInfo`, params)
  },
  addPublicServiceItems(params) {
    // 新增公共数据服务项目
    return axios.post(`${base.main}admin/Serving/addserving`, params)
  },
  editPublicServiceItems(params) {
    // 编辑公共数据服务项目
    return axios.post(`${base.main}admin/Serving/editserving`, params)
  },
  addPublicServiceItemsEquipment(params) {
    // 新增公共数据服务项目设备
    return axios.post(`${base.main}admin/Serving/addservinginfo`, params)
  },
  editPublicServiceItemsEquipment(params) {
    // 编辑公共数据服务项目设备
    return axios.post(`${base.main}admin/Serving/editservinginfo`, params)
  },
  delPublicServiceItemsEquipment(params) {
    // 删除公共数据服务项目设备
    return axios.post(`${base.main}admin/Serving/delinfo`, params)
  },
  getPublicServiceItemsEquipmentInfo(params) {
    // 获取公共数据服务项目设备详情
    return axios.post(`${base.main}admin/Serving/readInfo`, params)
  },

  // 开始服务
  getCorderorderStart(params) {
    // 获取公共数据服务项目设备详情
    return axios.post(`${base.main}admin/Corder/orderStart`, params)
  },
  // 完成服务
  getCorderfinishedAtOrder(params) {
    // 获取公共数据服务项目设备详情
    return axios.post(`${base.main}admin/Corder/finishedAtOrder`, params)
  },
  
  // getMainServiceConfig() {
  //   // 获取主线的服务配置
  //   return axios.post(`${base.main}admin/Businessrole/index`)
  // },
  getMainServiceConfig() {
    // 获取主线的服务配置
    return axios.post(`${base.main}admin/Buserving/businesscate`)
  },
  getMainService(params) {
    // 获取主线的服务类型
    return axios.post(`${base.main}admin/Buserving/businesscate`, params)
  },
  getMainServiceItem(params) {
    // 获取主线的服务项目
    return axios.post(`${base.main}admin/Buserving/buserving`, params)
  },
  getPublicServiceItemsEquipmentFilter(params) {
    // 获取公共数据服务项目和设备（过滤已添加）
    return axios.post(`${base.main}admin/Buserving/bcallservinginfo`, params)
  },
  getNotPunlicService() {
    // 获取未添加到主线的公共数据源服务类型
    const params = {
      bcid: 1
    }
    return axios.post(`${base.main}admin/Buserving/allServingCate`, params)
  },
  getStore() {
    // 获取门店列表
    return axios.post(`${base.main}admin/Store/index`)
  },
  getStoreInfo(params) {
    // 获取门店详情
    return axios.post(`${base.main}admin/Store/stroeInfo`, params)
  },
  getStoreDisable(params) {
    // 获取门店禁用启用
    return axios.post(`${base.main}admin/Store/delStore`,params)
  },

  getStoreAdd(params) {
    // 添加门店
    return axios.post(`${base.main}admin/Store/addStore`, params)
  },


  getCommunity() {
    // 获取社区列表
    return axios.post(`${base.main}admin/Community/index`)
  },

  getCommunityInfo(params) {
    // 获取社区详情
    return axios.post(`${base.main}admin/Community/communityInfo`, params)
  },

  getCommunitySpread(params) {
    // 旗下推广员
    return axios.post(`${base.main}admin/Community/getCommunitySpread`, params)
  },

  getCommunityServicer(params) {
    // 获得旗下所属的师傅列表
    return axios.post(`${base.main}admin/Community/getCommunityServicer`, params)
  },

  getaddCommunity(params) {
    // 添加社区
    return axios.post(`${base.main}admin/Community/addCommunity`, params)
  },
  getDelCommunity(params) {
    // 获取门店禁用启用
    return axios.post(`${base.main}admin/Community/delCommunity`,params)
  },

  getNeedCommunity(params) {
    // 获取渠道商或终端绑定微信账号二维码链接
    return axios.post(`${base.main}admin/Bind/needCommunity`, params)
  },

  getbindCommunityOpenid(params){
     // 渠道商或终端绑定微信账号
    return axios.post(`${base.main}admin/Bind/bindCommunityOpenid`, params)
  },
  
  getComnunityNeed(params){
    //获取二维码
    return axios.post(`${base.main}admin/Bind/comnunityspreadNeed`, params)
  },
  getBecomeCommunitySpread(params){
    //获取邀请成为推广员接口第二步请求
    return axios.post(`${base.main}admin/Bind/becomeCommunitySpread`, params)
  },

  getNeedSwtichCommunity(params){
    // 切换社区账号
    return axios.post(`${base.main}admin/Bind/needSwtichCommunity`, params)
  },
  getBindCommunityOpenid(params){
    // 切换社区账号换绑微信
    return axios.post(`${base.main}admin/Bind/bindCommunityOpenid`, params)
  },

  getNeedBecomecompany(params){
    // 切换社区账号换绑微信
    return axios.post(`${base.main}admin/Bind/needBecomecompany`, params)
  },
  // getBecomeCommunitySpread(params){
  //   // 切换社区账号换绑微信
  //   return axios.post(`${base.main}admin/Bind/becomeCommunitySpread`, params)
  // },
  getPointsList(){
    return axios.post(`${base.main}admin/Store/pointsList`)
  },

  getNeedStore(params) {
    // 获取渠道商或终端绑定微信账号二维码链接
    return axios.post(`${base.main}admin/Bind/needStore`, params)
  },

  getbindStoreOpenid(params){
     // 渠道商或终端绑定微信账号
    return axios.post(`${base.main}admin/Bind/bindStoreOpenid`, params)
  },


  addMainService(params) {
    // 添加主线的服务类型
    return axios.post(`${base.main}admin/Buserving/createbusinesscate`, params)
  },
  addMainServiceEquipment(params) {
    // 添加主线的服务设备
    return axios.post(`${base.main}admin/Buserving/addInfo`, params)
  },
  setServiceStatus(params) {
    // 设置服务项目、设备上下架
    return axios.post(`${base.main}admin/Buserving/upBcStatus`, params)
  },
  getServiceAddress(params) {
    // 获取服务地址（分布式（点对点））
    return axios.post(
      `${base.main}admin/Buserving/bcServingAddressPoint`,
      params
    )
  },
  addServiceAddress(params) {
    // 添加服务地址（分布式（点对点））
    return axios.post(`${base.main}admin/Buserving/addressPointAdd`, params)
  },
  changeServiceAddressStatus(params) {
    // 更改服务地址状态（分布式（点对点））
    return axios.post(`${base.main}admin/Buserving/setStatusPoints`, params)
  },
  editServiceAddress(params) {
    // 编辑服务地址（分布式（点对点））
    return axios.post(`${base.main}admin/Buserving/ressPointedit`, params)
  },

  getServiceArea(params) {
    // 获取服务区域覆盖
    return axios.post(
      `${base.main}admin/Buserving/bcServingAddressArea`,
      params
    )
  },
  detectServiceArea(params) {
    // 检测服务区域覆盖是否有分布点
    return axios.post(`${base.main}admin/Buserving/bcservingAreaOne`, params)
  },
  addServiceArea(params) {
    // 添加服务区域覆盖
    return axios.post(`${base.main}admin/Buserving/areaServingAdd`, params)
  },
  changeServiceAreaStatus(params) {
    // 更改服务区域覆盖状态
    return axios.post(`${base.main}admin/Buserving/setbcArea`, params)
  },
  getClientUserBanner(params) {
    // 获取用户端banner
    return axios.post(`${base.main}admin/client/index`, params)
  },
  addClientUserBanner(params) {
    // 新增用户端banner
    return axios.post(`${base.main}admin/client/createBanner`, params)
  },
  addMemberPageFist(params) {
    // 新增用户端Logo和背景图
    return axios.post(`${base.main}admin/Client/addMemberPageFist`, params)
  },
  editClientUserBanner(params) {
    // 编辑用户端banner
    return axios.post(`${base.main}admin/client/editBanne`, params)
  },
  eidtMemberPageFist(params) {
    // 编辑用户端LOGO和背景图
    return axios.post(`${base.main}admin/Client/eidtMemberPageFist`, params)
  },
  setClientUserBannerStatus(params) {
    // 设置用户端banner显示/隐藏状态
    return axios.post(`${base.main}admin/client/upBannerStatus`, params)
  },
  delClientUserBanner(params) {
    // 删除用户端banner
    return axios.post(`${base.main}admin/client/delBanner`, params)
  },
  getClientUserNav(params) {
    // 获取用户端金刚区nav
    return axios.post(`${base.main}admin/client/section`, params)
  },
  getMemberPageFist(params) {
    // 获取用户端logo和背景图
    return axios.post(`${base.main}admin/Client/memberPageFist`, params)
  },
  addClientUserNav(params) {
    // 新增用户端金刚区nav
    return axios.post(`${base.main}admin/client/createSection`, params)
  },
  editClientUserNav(params) {
    // 编辑用户端金刚区nav
    return axios.post(`${base.main}admin/client/editSection`, params)
  },
  setClientUserNavStatus(params) {
    // 设置用户端金刚区nav显示/隐藏状态
    return axios.post(`${base.main}admin/client/upSectionStatus`, params)
  },
  delClientUserNav(params) {
    // 删除用户端金刚区nav
    return axios.post(`${base.main}admin/client/delSection`, params)
  },
  getClientUserTabBarIcon(params) {
    // 获取用户端tabBar图标
    return axios.post(`${base.main}admin/client/iconList`, params)
  },
  saveClientUserTabBarIcon(params) {
    // 保存用户端tabBar图标
    return axios.post(`${base.main}admin/client/editIcon`, params)
  },
  getClientUserNoticeInside(params) {
    // 获取用户端站内通知
    return axios.post(`${base.main}admin/Message/indexMember`, params)
  },
  getClientMasterTabBarIcon(params) {
    // 获取用户端tabBar图标
    return axios.post(`${base.main}admin/client/iconServier`, params)
  },
  addClientUserNoticeInside(params) {
    // 添加用户端站内通知
    return axios.post(`${base.main}admin/Message/insertMemberMessage`, params)
  },
  changeClientUserNoticeInside(params) {
    // 更改用户端站内通知
    return axios.post(`${base.main}admin/Message/editMemberIndex`, params)
  },
  getClientMasterNoticeInside(params) {
    // 获取师傅端站内通知
    return axios.post(`${base.main}admin/Message/indexServicer`, params)
  },
  addClientMasterNoticeInside(params) {
    // 添加师傅端站内通知
    return axios.post(`${base.main}admin/Message/insertServicerMessage`, params)
  },
  changeClientMasterNoticeInside(params) {
    // 更改师傅端站内通知
    return axios.post(`${base.main}admin/Message/editServicerIndex`, params)
  },
  delNoticeInside(params) {
    // 删除站内通知（用户、师傅通用）
    return axios.post(`${base.main}admin/Message/del`, params)
  },
  changeNoticeInsideStatus(params) {
    // 更改站内通知上下线状态（用户、师傅通用）
    return axios.post(`${base.main}admin/Message/Updisplay`, params)
  },
  getNoticeInsideInfo(params) {
    // 获取站内通知（用户、师傅通用）
    return axios.post(`${base.main}admin/Message/read`, params)
  },
  getNoticeOuter(params) {
    // 获取站外通知列表
    return axios.post(`${base.main}admin/Out/index`, params)
  },
  changeNoticeOuterTriggerType(params) {
    // 更改站外通知触发类型
    return axios.post(`${base.main}admin/Out/setType`, params)
  },
  changeNoticeOuterStatus(params) {
    // 修改站外通知状态
    return axios.post(`${base.main}admin/Out/setConfig`, params)
  },
  getNoticeOuterTrigger(params) {
    // 获取站外通知触发人员列表
    return axios.post(`${base.main}admin/Out/reachUser`, params)
  },
  addNoticeOuterTrigger(params) {
    // 添加站外通知触发人员
    return axios.post(`${base.main}admin/Out/insertReachUser`, params)
  },
  delNoticeOuterTrigger(params) {
    // 删除站外通知触发人员
    return axios.post(`${base.main}admin/Out/del`, params)
  },
  saveClientMasterTabBarIcon(params) {
    // 保存师傅端tabBar图标
    return axios.post(`${base.main}admin/client/editservicericon`, params)
  },
  getOrderItem(params) {
    // 获取单品订单列表
    return axios.post(`${base.main}admin/order/index`, params)
  },
  getOrderItemDevice(params) {
    // 获取单品订单设备列表
    return axios.post(`${base.main}admin/order/findOrderInfo`, params)
  },
  changeOrderItemTime(params) {
    // 更改订单预约上门时间
    return axios.post(`${base.main}admin/order/changeBookingAt`, params)
  },
  cancelOrderItem(params) {
    // 取消订单
    return axios.post(`${base.main}admin/order/orderCancel`, params)
  },
  getDispatchMaster(params) {
    // 可派单师傅
    return axios.post(`${base.main}admin/order/transferServicer`, params)
  },
  dispatchMaster(params) {
    // 指定\转派师傅
    return axios.post(`${base.main}admin/order/transferOrder`, params)
  },
  getOrderItemInfo(params) {
    // 获取单品订单详情
    return axios.post(`${base.main}admin/order/servingOrderInfo`, params)
  },
  getOrderItemAccounts(params) {
    // 获取单品订单账目列表
    return axios.post(`${base.main}admin/account/index`, params)
  },
  getOrderItemAccountsInfo(params) {
    // 获取单品订单账目详情
    return axios.post(`${base.main}admin/account/read`, params)
  },
  findOrderExport(params) {
    // 查询订单导出数量
    return axios.post(`${base.main}admin/Export/findTimeOrder`, params)
  },
  orderExport(params) {
    // 订单导出
    return axios.post(`${base.main}admin/Export/needData`, params)
  },


  getOrderGoodsAccounts(params) {
    // 获取商品订单账目列表
    return axios.post(`${base.main}admin/Account/indexG`, params)
  },
  getOrderGoodsAccountsInfo(params) {
    // 获取商品订单账目详情
    return axios.post(`${base.main}admin/account/readG`, params)
  },
  getOrderSurroundingsAccounts(params) {
    // 获取环境治理订单账目列表
    return axios.post(`${base.main}admin/Account/indexE`, params)
  },
  getOrderSurroundingsAccountsInfo(params) {
    // 获取环境治理订单账目详情
    return axios.post(`${base.main}admin/account/readE`, params)
  },


  getCorderItem(params) {
    // 获取汽车美容订单列表
    return axios.post(`${base.main}admin/Corder/index`, params)
  },
  getCorderItemDevice(params) {
    // 获取汽车美容订单设备列表
    return axios.post(`${base.main}admin/Corder/findOrderInfo`, params)
  },
  getServingOrderInfo(params) {
    // 获取汽车美容订单详情
    return axios.post(`${base.main}admin/Corder/servingOrderInfo`, params)
  },


  getAccountIinedxCard(params) {
    // 获取汽车美容订单账单列表
    return axios.post(`${base.main}admin/Account/inedxCard`, params)
  },

  getAccountreadCard(params) {
    // 获取汽车美容订单账目详情
    return axios.post(`${base.main}admin/account/readCard`, params)
  },

  getWithdrawalApproveAccounting(params) {
    // 获取提现待审批列表（会计）
    return axios.post(`${base.main}admin/withdraw/index`, params)
  },
  getWithdrawalRecordAccounting(params) {
    // 获取提现记录列表（会计）
    return axios.post(`${base.main}admin/withdraw/aindexLog`, params)
  },
  getWithdrawalApproveAccountingInfo(params) {
    // 获取提现待审批详情（会计）
    return axios.post(`${base.main}admin/withdraw/read`, params)
  },
  withdrawalApproveAccounting(params) {
    // 提现审批（会计）
    return axios.post(`${base.main}admin/withdraw/setAstatus`, params)
  },
  getWithdrawalApproveCashier(params) {
    // 获取提现待审批列表（出纳）
    return axios.post(`${base.main}admin/withdraw/cindex`, params)
  },
  getWithdrawalRecordCashier(params) {
    // 获取提现记录列表（出纳）
    return axios.post(`${base.main}admin/withdraw/cindexLog`, params)
  },
  getWithdrawalApproveCashierInfo(params) {
    // 获取提现待审批详情（出纳）
    return axios.post(`${base.main}admin/withdraw/cread`, params)
  },
  withdrawalApproveCashier(params) {
    // 提现审批（出纳）
    return axios.post(`${base.main}admin/withdraw/setCstatus`, params)
  },
  getPersonalInfo() {
    // 获取个人中心信息
    return axios.post(`${base.main}admin/Personal/index`)
  },
  getPersonalWallet() {
    // 获取个人中心钱包余额
    return axios.post(`${base.main}admin/Personal/account`)
  },
  getPersonalWalletFlow(params) {
    // 获取个人钱包流水
    return axios.post(`${base.main}admin/Personal/accountLog`, params)
  },
  getPerformance(params) {
    // 获取个人业绩列表
    return axios.post(`${base.main}admin/Personal/withdraw`, params)
  },
  initiateWithdraw(params) {
    // 发起业绩提现
    return axios.post(`${base.main}admin/Personal/create`, params)
  },
  getWithdrawing() {
    // 获取个人业绩提现中列表
    return axios.post(`${base.main}admin/Personal/withdrawIng`)
  },
  getWithdrawalsRecord(params) {
    // 获取个人业绩提现历史列表
    return axios.post(`${base.main}admin/Personal/withdrawLog`, params)
  },
  getStatistics() {
    // 获取数据分析
    return axios.post(`${base.main}admin/Personal/analyzeData`)
  },
  getEnterpriseOrder(params) {
    // 获取企业订单
    return axios.post(`${base.main}admin/Company/index`, params)
  },
  recordEnterpriseOrder(params) {
    // 企业订单记录回访
    return axios.post(`${base.main}admin/Company/upOrder`, params)
  },
  getActivity() {
    // 获取支线业务（活动）列表
    return axios.post(`${base.main}admin/Activity/activities`)
  },
  getActivityInfo(params) {
    // 获取支线业务（活动）详情
    return axios.post(`${base.main}admin/Activity/read`, params)
  },
  changeActivityStatus(params) {
    // 修改支线业务（活动）上下线状态
    return axios.post(`${base.main}admin/Activity/activitiesStatus`, params)
  },
  changeActivityTime(params) {
    // 修改支线业务（活动）时间
    return axios.post(`${base.main}admin/Activity/setStartAtAndEndAt`, params)
  },
  addActivityCombo(params) {
    // 新增支线业务（活动）套餐
    return axios.post(
      `${base.main}admin/Activity/addBcActivityServingInfo`,
      params
    )
  },
  changeActivityCombo(params) {
    // 修改支线业务（活动）套餐
    return axios.post(
      `${base.main}admin/Activity/editBcActivityServingInfo`,
      params
    )
  },
  removeActivityCombo(params) {
    // 删除支线业务（活动）套餐
    return axios.post(`${base.main}admin/Activity/delActvityServing`, params)
  },
  getActivityComboAddress(params) {
    // 新增支线业务（活动）套餐点对点地址
    return axios.post(`${base.main}admin/Activity/acAddressPoint`, params)
  },
  addActivityComboAddress(params) {
    // 新增支线业务（活动）套餐点对点地址
    return axios.post(`${base.main}admin/Activity/addAcPointAdd`, params)
  },
  changeActivityComboAddress(params) {
    // 修改支线业务（活动）套餐点对点地址
    return axios.post(`${base.main}admin/Activity/pointsEdit`, params)
  },
  changeActivityComboAddressStatus(params) {
    // 修改支线业务（活动）套餐点对点地址状态（禁用、启用）
    return axios.post(`${base.main}admin/Activity/setStatusAcPoints`, params)
  },
  removeActivityComboAddress(params) {
    // 删除支线业务（活动）套餐点对点地址
    return axios.post(`${base.main}`, params)
  },
  getActivityComboArea(params) {
    // 获取支线业务（活动）套餐区域覆盖
    return axios.post(`${base.main}admin/Activity/acServingAddressArea`, params)
  },
  addActivityComboArea(params) {
    // 新增支线业务（活动）套餐区域覆盖
    return axios.post(`${base.main}admin/Activity/areaAcAdd`, params)
  },
  checkActivityComboArea(params) {
    // 检查支线业务（活动）套餐区域覆盖是否存在
    return axios.post(`${base.main}admin/Activity/acAreaOne`, params)
  },
  changeActivityComboAreaStatus(params) {
    // 修改支线业务（活动）套餐区域覆盖状态（禁用、启用）
    return axios.post(`${base.main}admin/Activity/setAcArea`, params)
  },
  removeActivityComboArea(params) {
    // 删除支线业务（活动）套餐区域覆盖
    return axios.post(`${base.main}`, params)
  },
  getChannelBusiness(params) {
    // 获取渠道商列表
    return axios.post(`${base.main}admin/Business/index`, params)
  },
  getChannelBusinessInfo(params) {
    // 获取渠道商详情
    return axios.post(`${base.main}admin/Business/findBusiness`, params)
  },
  addChannelBusiness(params) {
    // 添加渠道商
    return axios.post(`${base.main}admin/Business/add`, params)
  },
  changeChannelBusiness(params) {
    // 修改渠道商
    return axios.post(`${base.main}admin/Business/edit`, params)
  },
  getChannelBindWXLink(params) {
    // 获取渠道商或终端绑定微信账号二维码链接
    return axios.post(`${base.main}admin/Bind/needBusiness`, params)
  },
  channelBindWX(params) {
    // 渠道商或终端绑定微信账号
    return axios.post(`${base.main}admin/Bind/bindBusinessOpenid`, params)
  },
  getChannelTerminal(params) {
    // 获取渠道终端
    return axios.post(`${base.main}admin/Business/terminal`, params)
  },
  getChannelTerminalInfo(params) {
    // 获取渠道终端详情
    return axios.post(`${base.main}admin/Business/findBusiness`, params)
  },
  addChannelTerminal(params) {
    // 添加渠道终端
    return axios.post(`${base.main}admin/Business/terminalAdd`, params)
  },
  changeChannelTerminal(params) {
    // 修改渠道终端
    return axios.post(`${base.main}admin/Business/terminaledit`, params)
  },
  delChannel(params) {
    // 删除渠道或终端
    return axios.post(`${base.main}admin/Business/terminalDel`, params)
  },
  onChangeAccount() {
    // 切换账号
    return axios.post(`${base.main}admin/Admin/switchAccount`)
  },
  getVoucher(params) {
    // 获取代金券
    return axios.post(`${base.main}admin/Cardbag/index`, params)
  },
  getVoucherInfo(params) {
    // 获取代金券详情
    return axios.post(`${base.main}admin/Cardbag/read`, params)
  },
  getVoucherUser(params) {
    // 获取代金券发放用户列表
    return axios.post(`${base.main}admin/Cardbag/userListOpenId`, params)
  },
  voucherUserIssue(params) {
    // 发放代金券
    return axios.post(`${base.main}admin/Cardbag/sendManyUser`, params)
  },
  sendMessage(params) {
    // 发送验收短信
    return axios.post(`${base.main}admin/order/checkMessage`, params)
  },
  businessApplyList(params) {
    // 渠道商申请列表
    return axios.post(`${base.main}admin/Business/businessApplyList`, params)
  },
  //同意
  acceptApply(params) {
    // 渠道商申请列表
    return axios.post(`${base.main}admin/Business/acceptApply`, params)
  },
  checkBindWX(params, config) {
    // 提现前检查是否绑定微信小程序账号
    return axios.post(
      `${base.main}admin/Personal/ifMemberAcoount`,
      params,
      config
    )
  },
  getPublicSurroundings(params) {
    // 获取公共数据-环境治理服务列表
    return axios.post(`${base.main}admin/Servingenvigover/index`, params)
  },
  getPublicSurroundingsInfo(params) {
    // 获取公共数据-环境治理服务详情
    return axios.post(
      `${base.main}admin/Servingenvigover/findEnviGoverInfo`,
      params
    )
  },
  addPublicSurroundings(params) {
    // 新增公共数据-环境治理服务
    return axios.post(`${base.main}admin/Servingenvigover/add`, params)
  },
  editPublicSurroundings(params) {
    // 修改公共数据-环境治理服务
    return axios.post(`${base.main}admin/Servingenvigover/edit`, params)
  },
  addPublicSurroundingsItem(params) {
    // 新增公共数据-环境治理服务详细项
    return axios.post(
      `${base.main}admin/Servingenvigover/addEnviGoverInfo`,
      params
    )
  },
  editPublicSurroundingsItem(params) {
    // 修改公共数据-环境治理服务详细项
    return axios.post(
      `${base.main}admin/Servingenvigover/editEnviGoverInfo`,
      params
    )
  },
  delPublicSurroundingsItem(params) {
    // 删除公共数据-环境治理服务详细项
    return axios.post(`${base.main}admin/Servingenvigover/delInfo`, params)
  },
  getPublicGoods(params) {
    // 获取公共数据-商品分类
    return axios.post(`${base.main}admin/Goods/index`, params)
  },
  getPublicGoodsInfo(params) {
    // 获取公共数据-商品分类详情
    return axios.post(`${base.main}admin/Goods/findGoods`, params)
  },
  addPublicGoods(params) {
    // 新增公共数据-商品分类
    return axios.post(`${base.main}admin/Goods/add`, params)
  },
  editPublicGoods(params) {
    // 修改公共数据-商品分类
    return axios.post(`${base.main}`, params)
  },
  addPublicGoodsItem(params) {
    // 新增公共数据-商品
    return axios.post(`${base.main}admin/Goods/addGoodsInfo`, params)
  },
  editPublicGoodsItem(params) {
    // 修改公共数据-商品
    return axios.post(`${base.main}admin/Goods/editGoodsInfo`, params)
  },
  delPublicGoodsItem(params) {
    // 删除公共数据-商品
    return axios.post(`${base.main}admin/Goods/delInfo`, params)
  },
  getSurroundingsOrder(params) {
    // 获取环境治理订单列表
    return axios.post(`${base.main}admin/Enviorder/index`, params)
  },
  // 环境治理-start
  getSurroundingsItem(params) {
    // 获取主线业务环境治理服务列表
    return axios.post(`${base.main}admin/Benvigover/buserving`, params)
  },
  getPublicSurroundingsItemFilter(params) {
    // 获取公共数据-获取主线业务环境治理-服务项目（过滤已添加）
    return axios.post(`${base.main}admin/Benvigover/bcAllServingInfo`, params)
  },
  addSurroundingsItem(params) {
    // 添加主线环境治理的服务
    return axios.post(`${base.main}admin/Benvigover/addInfo`, params)
  },
  // 购物商城-start
  getOrderGoods(params) {
    // 获取商品订单列表
    return axios.post(`${base.main}admin/Gorder/index`, params)
  },
  getOrderGoodsItem(params) {
    // 获取商品订单详细项
    return axios.post(`${base.main}admin/Gorder/findOrderInfo`, params)
  },
  getOrderGoodsInfo(params) {
    // 获取商品订单详情
    return axios.post(`${base.main}admin/Gorder/servingOrderInfo`, params)
  },
  finishOrderGoods(params) {
    // 完成商品订单
    return axios.post(`${base.main}admin/Gorder/finishAction`, params)
  },
  cancelOrderGoods(params) {
    // 取消商品订单
    return axios.post(`${base.main}admin/Gorder/orderCancel`, params)
  },
  // 环境治理-end
  // 购物商城-start
  getGoodsItem(params) {
    // 获取主线业务商品列表
    return axios.post(`${base.main}admin/Bugoods/buserving`, params)
  },
  getPublicGoodsItemFilter(params) {
    // 获取公共数据-获取主线业务商城商品（过滤已添加）
    return axios.post(`${base.main}admin/Bugoods/bcAllServingInfo`, params)
  },
  addGoodsItem(params) {
    // 添加主线商城商品
    return axios.post(`${base.main}admin/Bugoods/addInfo`, params)
  },
  // 购物商城-end
  editBcInfoRealPrice(params) {
    // 添加主线业务服务、商品等实际售价 - 公用
    return axios.post(
      `${base.main}admin/Businessrole/editBcInfoRealPrice`,
      params
    )
  },
  getClientLine() {
    // 获取用户端小程序首页信息流业务线
    return axios.post(`${base.main}admin/Client/message`)
  },
  changeClientLineStatus(params) {
    // 改变用户端小程序首页信息流业务线显示状态
    return axios.post(`${base.main}admin/Client/setMessage`, params)
  },
  getSpecification() {
    // 获取服务、商品规格列表
    return axios.post(`${base.main}admin/Specification/index`)
  },
  addSpecification(params) {
    // 添加服务、商品规格
    return axios.post(`${base.main}admin/Specification/add`, params)
  },
  changeSpecification(params) {
    // 改变服务、商品规格
    return axios.post(`${base.main}admin/Specification/edit`, params)
  },
  getActivityOrder(params) {
    // 获取活动订单列表
    return axios.post(`${base.main}admin/Actiorder/index`, params)
  },
  getActivityOrderInfo(params) {
    // 获取活动订单列表
    return axios.post(`${base.main}admin/Actiorder/servingOrderInfo`, params)
  },
  getActivityOrderDevice(params) {
    // 获取活动订单设备列表
    return axios.post(`${base.main}admin/Actiorder/findOrderInfo`, params)
  },
  changeActivityOrderTime(params) {
    // 更改活动订单预约上门时间
    return axios.post(`${base.main}admin/Actiorder/changeBookingAt`, params)
  },
  cancelActivityOrder(params) {
    // 取消活动订单
    return axios.post(`${base.main}admin/Actiorder/orderCancel`, params)
  },
  categoryInsert(params) {
    // 添加分类
    return axios.post(`${base.main}admin/Serving/categoryInsert`, params)
  },
  getEditInfo(params) {
    // 主线业务编辑售价
    return axios.post(`${base.main}admin/Buserving/editInfo`, params)
  },
  //服务分类
  getCateIndex() {
    // 主线业务编辑售价
    return axios.post(`${base.main}admin/serving/CateIndex`)
  },
  //监工管理
  getFowller(params) {
    return axios.post(`${base.main}admin/Fowller/index`, params)
  },
  //监工管理添加
  getFowllerAdd(params) {
    return axios.post(`${base.main}admin/Fowller/add`, params)
  },
  getNeedFowller(params) {
    // 监工管理获取微信二维码
    return axios.post(`${base.main}admin/Bind/needFowller`, params)
  },
  getBindFowllerOpenid(params) {
    // 监工管理绑定微信
    return axios.post(`${base.main}admin/Bind/bindFowllerOpenid`, params)
  },
  getFindFowller(params) {
    // 监工管理详情
    return axios.post(`${base.main}admin/Fowller/findFowller`, params)
  },
  getBindFowller(params) {
    // 选择监工绑定
    return axios.post(`${base.main}admin/Community/bindFowller`, params)
  },
  getDelFowller(params) {
    // 监工禁用启用
    return axios.post(`${base.main}admin/Fowller/delFowller`, params)
  },
  chateCommunityBcRoseRate(params) {
    // 渠道经理业务比例列表
    return axios.post(`${base.main}admin/Community/chateCommunityBcRoseRate`, params)
  },
  openExtractRate(params) {
    // 渠道经理业务比例列表
    return axios.post(`${base.main}admin/Community/openExtractRate`, params)
  },
  getBusinessConfig(params) {
    // 服务比例配置
    return axios.post(`${base.main}admin/Personal/businessConfig`, params)
  },
  getBcRoselist(params) {
    // 渠道经理某业务主线配置数据
    return axios.post(`${base.main}admin/Personal/bcRoselist`, params)
  },
  getRoseRate(params) {
    // 参与分成的角色数据
    return axios.post(`${base.main}admin/Personal/roseRate`, params)
  },
  getAddrole(params) {
    // 添加业务角色分成比例
    return axios.post(`${base.main}admin/Personal/addrole`, params)
  },
  getEditrole(params) {
    // 编辑渠道经理某一个业务主线的角色分成
    return axios.post(`${base.main}admin/Personal/editrole`, params)
  },
  getDelBcRole(params) {
    //删除渠道经理某一个业务的分配比例
    return axios.post(`${base.main}admin/Personal/delBcRole`, params)
  },
  getAssignRate(params) {
    //业务员的分配比例
    return axios.post(`${base.main}admin/Community/assignRate`, params)
  },
  getApplyBussiness(params) {
    //新增邀请渠道商
    return axios.post(`${base.main}admin/Personal/applyBussiness`, params)
  },
  getCityCateServing(params) {
    //省市区设备
    return axios.post(`${base.main}admin/Area/cityCateServing`, params)
  },
  getCityCateServingAllInsery(params) {
    //导入
    return axios.post(`${base.main}admin/area/cityCateServingAllInsery`, params)
  },
  getEditServingInfo(params) {
    //编辑某一地区服务项目设备
    return axios.post(`${base.main}admin/Area/editServingInfo`, params)
  },
  getReadInfo(params) {
    //某一城市地区服务设备详情
    return axios.post(`${base.main}admin/Area/readInfo`, params)
  },
  getDelInfo(params) {
    //删除某一城市地区服务设备详情
    return axios.post(`${base.main}admin/area/delInfo`, params)
  },
  addServingInfoCityAll(params) {
    //导入某一城市地区全部服务设备详情
    return axios.post(`${base.main}admin/Area/addServingInfoCityAll`, params)
  },
  getFindServingInfo(params) {
    //查找某一城市地区全部服务设备详情
    return axios.post(`${base.main}admin/Area/findServingInfo`, params)
  },
  getServingInfo(params) {
    //获取地区服务详情
    return axios.post(`${base.main}admin/area/servingInfo`, params)
  },
  getEditServing(params) {
    //编辑地区服务
    return axios.post(`${base.main}admin/Area/editServing`, params)
  },
  getBuserving(params) {
    //城市业务服务数据及设备①
    return axios.post(`${base.main}admin/Areabuserving/buserving`, params)
  },
  getBcAllServingInfo(params) {
    //城市公共源数据（剔除已勾选的设备)②
    return axios.post(`${base.main}admin/Areabuserving/bcAllServingInfo`, params)
  },
  getAddInfo(params) {
    //城市化业务-添加服务设备和服务项目③
    return axios.post(`${base.main}admin/Areabuserving/addInfo`, params)
  },
  getEditInfoAred(params) {
    //编辑某一城市业务服务设备信息【⑤】
    return axios.post(`${base.main}admin/Areabuserving/editInfo`, params)
  },
  getUpBcStatus(params) {
    //上下架某一城市业务服务设备信息【⑤】
    return axios.post(`${base.main}admin/Areabuserving/upBcStatus`, params)
  },
}

export default api
