export default {
  setToken(state, provider) {
    state.token = provider
  },
  setQiniuData(state, provider) {
    state.qiniuData = provider
  },
  setPageButtons(state, provider) {
    state.pageButtons = provider
  },
  changeRefreshApprentice(state, provider) {
    state.refreshApprentice = provider
  }
}
