export default {
  version: '3.1.9',
  token: '',
  qiniuData: {},
  pageButtons: [],
  list:[],
  refreshApprentice: null, // 刷新学徒列表
  $orderStatusText: {
    0: '提交订单',
    1: '支付订单',
    2: '确认接单',
    3: '开始上门',
    4: '到达/开始服务',
    5: '服务完成',
    6: '服务验收和评价',
    7: '用户取消订单',
    8: '平台取消订单'
  },
  $CorderStatusText: {
    0: '待支付',
    1: '待确认',
    2: '开始服务',
    3: '服务完成',
    4: '验收和评分',
    5: '用户取消订单',
    6: '平台取消订单',
    7: '关闭'
  },
  $orderTypeText: {
    1: '单品家电清洗',
    2: '秒杀订单',
    3: '双十一订单活动',
    4: '元旦活动',
    5: '送清风活动'
  },
  $stockTypes: {
    // 代金券类型
    COUPON: '普通代金券',
    DISCOUNT: '免充值券'
  }
}
