<template>
  <a-menu
    theme="dark"
    mode="inline"
    :inlineIndent="16"
    v-model="sideIndex"
    :open-keys="openKeys"
    :inline-collapsed="collapsed"
    @click="menuSelect"
    @openChange="openChange"
  >
    <template v-for="item in sidebar">
      <a-menu-item v-if="!item.children" :key="item.id" :value="item.url">
        <my-icon :type="item.icon" />
        <span>{{ item.name }}</span>
      </a-menu-item>
      <sub-menu v-else :itemValue="item" :key="item.id" />
    </template>
  </a-menu>
</template>
<script>
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2401787_i572plwovpa.js' // 在 iconfont.cn 上生成
})
import subMenu from './sub-menu'
export default {
  data() {
    return {
      sideIndex: [], // 选择导航栏索引
      openKeys: [] // 展开导航栏索引
    }
  },
  components: {
    subMenu,
    MyIcon
  },
  props: {
    sidebar: {
      type: Array,
      default: function() {
        return []
      }
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    menuSelect(e) {
      // 导航栏点击事件
      if (e) {
        // ui组件点击事件
        const data = {
          key: e.key,
          keyPath: e.keyPath,
          url: e.item.value
        }
        this.jump(data)
      } else {
        // 首页初始化调用
        let navPathData = window.sessionStorage.getItem('navPathData')
        if (navPathData) {
          navPathData = JSON.parse(navPathData)
          this.openKeys = navPathData.openKeys
          this.sideIndex = navPathData.sideIndex
        } else {
          const item = this.sidebar[0]
          const openKeys = [item.id]
          const sideIndex = []
          if (item.children) {
            openKeys.push(item.children[0].id)
            sideIndex.push(item.children[0].id)
          } else {
            sideIndex.push(item.id)
          }
          this.openKeys = openKeys
          this.sideIndex = sideIndex
        }
        let permission = window.sessionStorage.getItem('permission')
        if (permission) {
          permission = JSON.parse(permission)
          const data = permission.find((v) => v.id === this.sideIndex[0])
          this.jump({
            url: data.url
          })
        }
      }
    },
    openChange(e) {
      // SubMenu 展开/关闭的回调
      if (e.length < 2) {
        this.openKeys = e
      } else {
        this.openKeys = [e[e.length - 1]]
      }
    },
    jump(e) {
      const routeData = this.$route
      if (routeData.path === e.url) return
      this.$router.replace(e.url)
      if (!e.keyPath) return
      const navPathData = JSON.stringify({
        sideIndex: [e.key],
        openKeys: e.keyPath
      })
      window.sessionStorage.setItem('navPathData', navPathData)
    }
  }
}
</script>
<style>
.ant-menu-inline-collapsed {
  width: 48px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 16px !important;
}
</style>
