<template>
  <a-layout>
    <a-layout-sider width="208" collapsedWidth="48" v-model="collapsed">
      <div class="logo" :class="{ 'logo-width-collapsed': collapsed }">
        <img
          class="logo-icon logo-icon-2"
          src="../../assets/image/logo-2.png"
          alt="logo"
          v-if="collapsed"
        />
        <img
          class="logo-icon logo-icon-1"
          src="../../assets/image/logo-1.png"
          alt="logo"
          v-else
        />
      </div>
      <nav-menu ref="navMenu" :collapsed="collapsed" :sidebar="sidebar" />
      <a-button class="collapsed-btn" type="link" @click="toggleCollapsed">
        <a-icon
          class="sider-btn-icon"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        />
      </a-button>
    </a-layout-sider>
    <a-layout-content>
      <a-layout-header>
        <div class="btn-box">
          <!-- <a-button type="link">
            <a-icon class="btn-icon" type="search" />
          </a-button>
          <a-button type="link">
            <a-icon class="btn-icon" type="bell" />
          </a-button> -->
          <div class="out" @click="outClick">
            <div class="user">
              <img
                class="avatar"
                src="../../assets/image/avatar.png"
                alt="avatar"
              />
              <span class="user-name">{{ name }}</span>
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-breadcrumb separator=">" v-if="pathData.length">
        <template v-for="(item, index) in pathData">
          <a-breadcrumb-item :key="index" v-if="item.text">
            <span
              :class="{ 'breadcrumb-link': !item.disabled }"
              @click="backClick(index, item.disabled)"
              >{{ item.text }}</span
            >
          </a-breadcrumb-item>
        </template>
        <a-breadcrumb-item v-if="currentPathName">
          {{ currentPathName }}
        </a-breadcrumb-item>
      </a-breadcrumb>

      <div id="main-wrap" class="main-wrap">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
    </a-layout-content>

    <div class="system" v-if="isOutBtn">
      <div class="user">
        <div class="name">阿六到家（中台）</div>
        <div class="item">
          <span class="label">登录账号：</span>
          <span class="value">{{ account }}</span>
        </div>
        <div class="item">
          <span class="label">账号类型：</span>
          <span class="value">{{ typeText[userType] }}</span>
        </div>
        <div class="item" style="position: relative">
          <span class="label">账号角色：</span>
          <span class="value">{{ userName }}</span>
          <!-- <div class="switchName">
            <a-button @click="switchClick()">换绑账号</a-button>
          </div> -->
        </div>
        <div class="item">
          <span class="label">持有人：</span>
          <span class="value">{{ name }}</span>
        </div>
      </div>
      <div class="btns">
        <div class="item" @click="onChangeAccount">切换账号</div>
        <div class="item item-2" @click="out">注销登录</div>
      </div>
    </div>
    <!-- <a-modal v-model="modalIndex" title="分享微信" :footer="false">
      <div class="codeWx">
        <iframe
          id="iframe"
          :src="wechatLink"
          width="300px"
          height="400px"
          frameborder="0"
          name="iframea"
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
        ></iframe>
      </div>
    </a-modal> -->
  </a-layout>
</template>
<script>
import { mapState } from 'vuex'
import navMenu from '@/components/nav-menu/index'
export default {
  data() {
    return {
      name: '', // 账号名称
      account: '', // 账号
      userType: '', // 账号类型
      userName: '', // 账号角色
      isOutBtn: false, // 是否显示账号操作
      collapsed: false, // 展开、收起二级导航栏
      sidebar: [], // 导航栏菜单
      pathData: [], // 面包屑
      currentPathName: '', // 当前路径名称
      clickNum: 0, // 面包屑可点击个数
      typeText: {
        Proxy: '代理企业',
        Madmin: '中台管理账号',
        Lector: '理论讲师',
        Training: '实操讲师',
        fowller:"监工"
      },
      subIndexList: [],
      modalIndex: false,
      // wechatLink: '',
      infoIndex: []
    }
  },
  computed: {
    ...mapState(['token'])
  },
  components: {
    navMenu
  },
  created() {
    this.name = window.sessionStorage.getItem('name')
    this.userName = window.sessionStorage.getItem('userName')
    this.userType = window.sessionStorage.getItem('userType')
    this.account = window.sessionStorage.getItem('systemNum')
    let subIndexListArry = window.sessionStorage.getItem('list')
    this.subIndexList = JSON.parse(subIndexListArry)
    let menuFormat = window.sessionStorage.getItem('menuFormat')
   
    if (menuFormat) {
      menuFormat = JSON.parse(menuFormat)
      this.sidebar = menuFormat
      // this.$nextTick(() => {
      //   this.$refs.navMenu.menuSelect()
      // })
    } else {
      let permission = window.sessionStorage.getItem('permission')
      if (permission) {
        permission = JSON.parse(permission)
        this.formatPurview(permission)
      }
    }
   
  },
  methods: {
    formatPurview(arr) {
      // 格式化权限列表
      const array = {}
      const returnArr = []
      arr.forEach((item) => {
        array[item.id] = item
      })
      arr.forEach((item) => {
        if (item.visible) {
          if (item.parent_id !== 0) {
            ;(
              array[item.parent_id].children ||
              (array[item.parent_id].children = [])
            ).push(item)
          } else {
            returnArr.push(item)
          }
        }
      })
      this.sidebar = returnArr
      window.sessionStorage.setItem('menuFormat', JSON.stringify(returnArr))
      this.$nextTick(() => {
        this.$refs.navMenu.menuSelect()
      })
    },
    toggleCollapsed() {
      // 展开、收起二级导航栏
      this.collapsed = !this.collapsed
    },
    outClick() {
      // 账号点击事件
      this.isOutBtn = !this.isOutBtn
    },
    
    onChangeAccount() {
      // 切换账号
      this.$confirm({
        title: '切换账号',
        content: '确认切换账号？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.$router.replace({
            path: '/login',
            query: {
              status: 'change'
            }
          })
        }
      })
    },
    out() {
      // 注销确认框
      this.$confirm({
        title: '注销',
        content: '确认注销登录？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          window.sessionStorage.clear()
          window.location.replace('/login')
        }
      })
    },
    setBreadcrumb(to, from, init) {
      const toMeta = to.meta
      const fromMeta = from.meta
      // console.log(toMeta)
      if (!toMeta.fromPath) return
      if (toMeta.fromPath.length) {
        // 当前路径有上级路径
        if (init) {
          // 刷新时，获取缓存的数据
          let pathData = window.sessionStorage.getItem('pathData')
          if (pathData) {
            pathData = JSON.parse(pathData)
            if (toMeta.pageLevel) {
              // 内页时赋值缓存的数据
              toMeta.current.forEach((f) => {
                // 查找是否有跟当前页面重复的，有则删除
                const i = pathData.findIndex((e) => e.text === f)
                if (i > -1) {
                  pathData.splice(i, 1)
                }
              })
              this.pathData = pathData
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(pathData)
              )
            } else {
              // 非内页，直接用路由信息的数据
              this.pathData = toMeta.fromPath
            }
          } else {
            this.pathData = toMeta.fromPath
          }
        } else {
          if (toMeta.pageLevel) {
            if (fromMeta.pageLevel > toMeta.pageLevel) {
              // 返回上一页时，删除最后一个数据后再赋值
              let pathData = window.sessionStorage.getItem('pathData')
              pathData = JSON.parse(pathData)
              pathData.splice(pathData.length - 1, 1)
              this.pathData = pathData
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(pathData)
              )
            } else if (fromMeta.pageLevel !== toMeta.pageLevel) {
              // 前进时，跟前面的页面数据合并
              this.pathData = [...this.pathData, ...toMeta.fromPath]
              window.sessionStorage.setItem(
                'pathData',
                JSON.stringify(this.pathData)
              )
            }
          } else {
            // 非子页面，直接用路由信息的数据
            this.pathData = toMeta.fromPath
          }
        }

        if (to.query.id) {
          // 当前页面是更新页面
          if (toMeta.current.length === 2) {
            this.currentPathName = toMeta.current[1]
          } else {
            this.currentPathName = toMeta.current[0]
          }
        } else {
          this.currentPathName = toMeta.current[0]
        }
      } else {
        this.pathData = []
      }
    },
    backClick(i, d) {
      // 面包屑跳转
      if (d) return
      const pathNum = this.pathData.length - i
      this.$router.go(-pathNum)
    },
    setPageButtons(e) {
      // 设置按页面钮
      this.$store.commit('setPageButtons', e)
    }
  },
  beforeRouteEnter(to, from, next) {
    // 监听路由跳转
    const token = window.sessionStorage.getItem('token')

    if (token) {
      next((vm) => {
        if (to.meta.buttons) {
          vm.setPageButtons(to.meta.buttons)
        }
        vm.setBreadcrumb(to, from, 'init')
      })
    } else {
      next({
        replace: true,
        path: '/login'
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    // 监听路由变化
    if (to.meta.pageLevel) {
      // 跳转内页
      if (
        to.meta.pageLevel === 1 &&
        from.meta.pageLevel === 0 &&
        !from.meta.keepAlive
      ) {
        from.meta.keepAlive = true // 上级缓存页面
      }
      if (to.meta.pageLevel > 1 && from.meta.keepAlive) {
        from.meta.keepAlive = false // 上级不缓存页面
      }
    } else {
      // 非跳转内页
      if (from.meta.keepAlive) {
        // 上一个页面缓存时，设置非缓存
        from.meta.keepAlive = false
      }
    }
    if (to.meta.buttons) {
      this.setPageButtons(to.meta.buttons)
    }
    this.setBreadcrumb(to, from)
    next()
  }
}
</script>
<style scoped>
.ant-layout {
  height: 100vh;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  height: 46px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  z-index: 99;
}

.ant-layout-header .btn-box {
  display: flex;
  align-items: center;
}

.ant-menu {
  flex: 1;
}

.logo {
  width: 208px;
  height: 46px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #111d2c;
}

.logo-width-collapsed {
  width: 46px;
}

.logo-icon {
  height: 22px;
  margin: 0 auto;
}

.logo-icon-1 {
  width: 133px;
}

.logo-icon-2 {
  width: 18px;
}

h1 {
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

.btn-icon {
  font-size: 20px;
  color: #111d2c;
}

.out {
  margin-right: 25px;
}

.out .user {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #111d2c;
  margin-left: 15px;
}

.out .avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.out .user-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-layout-sider >>> .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-menu {
  flex: 1;
}

.collapsed-btn {
  text-align: left;
}

.sider-btn-icon {
  flex-shrink: 1;
  font-size: 20px;
  color: #fff;
}

.system {
  width: 330px;
  position: fixed;
  top: 54px;
  right: 24px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 9;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(0, 21, 41, 0.1);
}

.system .name {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.system .user {
  padding: 24px;
  font-size: 14px;
  line-height: 22px;
}

.system .user .item:not(:last-child) {
  margin-bottom: 8px;
}

.system .label {
  color: rgba(0, 0, 0, 0.85);
}

.system .value {
  color: rgba(0, 0, 0, 0.65);
}

.system .btns {
  background-color: #f7f9fa;
  border: 1px solid #e9e9e9;
}

.system .btns {
  height: 48px;
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 0 0 4px 4px;
  display: flex;
}

.system .btns .item {
  flex: 1;
}

.system .btns .item-2 {
  font-weight: bold;
  position: relative;
}

.system .btns .item-2::before {
  content: '';
  width: 1px;
  height: 14px;
  background-color: #e8e8e8;
  position: absolute;
  left: 0;
  top: 16px;
}

.ant-layout-content {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.ant-breadcrumb {
  width: 100%;
  flex: 0 0 54px;
  background-color: #fff;
  line-height: 54px;
  padding: 0 16px;
  box-sizing: border-box;
}

.main-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

@media screen and (max-width: 1080px) {
  .main-wrap {
    overflow-x: auto;
  }
}
</style>
<style>
a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

.ant-breadcrumb .breadcrumb-link {
  cursor: pointer;
}

.ant-breadcrumb .breadcrumb-link:hover {
  color: #1890ff;
}

.pages {
  min-height: 100%;
  min-width: 1080px;
}

.ant-tabs {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.ant-tabs .ant-tabs-nav-container {
  padding: 0 24px;
}

.ant-tabs-content {
  flex: 1;
  overflow: hidden;
}

.ant-tabs-tabpane,
.ant-tabs-tabpane .main-content {
  height: 100%;
}

.container .ant-page-header {
  padding: 0 24px 16px 24px;
}

.container .ant-page-header,
.btn-wrap,
.main-wrap .ant-tabs-bar,
.ant-table-wrapper {
  background-color: #fff;
}

.ant-tabs-nav-container {
  padding: 0 16px;
}

.main-wrap .ant-tabs-bar {
  margin: 0;
}

.main-content {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.ant-steps-navigation {
  box-shadow: rgb(232, 232, 232) 0px -1px 0px 0px inset;
}

/* 搜索框-start */
.ribbon-wrap {
  background-color: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.ribbon-wrap .search-box,
.ribbon-wrap .btn-box {
  display: flex;
  align-items: center;
}

.ribbon-wrap .search-input {
  width: 180px;
  height: 32px;
}

.ribbon-wrap .btn-box .ant-btn:not(:last-child) {
  margin-right: 8px;
}
/* 搜索框-end */

.btn-wrap {
  padding: 24px 24px 0 24px;
}

.main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px 0 32px;
  background-color: #fff;
}

.main-content-header .title,
.main-content-header .title-1 {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.main-content-header .title-2 {
  font-size: 14px;
  color: #8c8c8c;
  font-weight: normal;
  margin-left: 8px;
}

.main-content-header .item .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.table-wrap {
  flex: 1;
  padding: 24px 32px;
  background-color: #fff;
  border-radius: 2px;
}

.ant-table-footer {
  padding: 16px 16px 0 16px;
  background-color: #fff;
}

.statistic-inline-block .ant-statistic-content,
.statistic-inline-block .ant-statistic-content-value-decimal,
.ant-table-wrapper .ant-statistic-content,
.ant-table-wrapper .ant-statistic-content-value-decimal,
.ant-table-wrapper .ant-statistic-content,
.ant-table-wrapper .ant-statistic-content-value-decimal,
.a-statistic .ant-statistic-content,
.a-statistic .ant-statistic-content-value-decimal {
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}

.a-statistic {
  display: inline-block;
}

.main-content .ant-table-thead > tr > th,
.main-content .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.main-content .ant-table-pagination.ant-pagination {
  float: left;
}

.main-content .ant-table-pagination.ant-pagination {
  margin: 16px 0 0 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.form-item {
  display: flex;
  justify-content: space-between;
}

.form-item .ant-form-item {
  width: calc(50% - 8px);
}

.form-item-border-top {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
  padding-top: 32px;
}

.IDcard-uploader .ant-upload.ant-upload-select-picture-card {
  width: 188px;
  height: 120px;
}

.main-content
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  white-space: pre;
}

.ant-modal .ant-modal-close-x {
  font-size: 26px;
}

.statistic-inline-block {
  line-height: 20px;
  display: inline-block;
}

/* 嵌入式页面-start */
.embedded-page-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px 100px;
  overflow-y: auto;
}

.embedded-page {
  height: 100%;
  background-color: #f0f2f5;
  overflow-y: auto;
  padding-top: 56px;
  border-radius: 2px;
}

.embedded-page .ant-page-header {
  display: none;
}

.embedded-page-header {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: absolute;
  top: 40px;
  right: 100px;
  bottom: 40px;
  left: 100px;
  z-index: 999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 24px;
}

.embedded-page-header-text {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}

.embedded-page-close-btn {
  font-size: 16px;
  cursor: pointer;
}
.switchName {
  position: absolute;
  right: 15%;
  top: -1px;
}
.ant-btn {
  height: 25px;
}
.codeWx {
  width: 300px;
  margin: 0 auto;
}
/* 嵌入式页面-end */
</style>
