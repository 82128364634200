/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import router from '../router'
import store from '../store'
import { message } from 'ant-design-vue'

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = (msg) => {
  message.error(msg)
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace('/login')
}

/**
 * 请求失败后的错误统一处理
 * @param { Number } status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin()
      break
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录')
      window.sessionStorage.removeItem('token')
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
    // 404请求不存在
    case 404:
      tip('请求的资源不存在')
      break
    default:
      tip(other)
      console.log(other)
  }
}

// 创建axios实例
const instance = axios.create({
  // baseURL在此处省略配置,考虑到项目可能由多人协作完成开发，域名也各不相同，此处通过对api的抽离，域名单独配置在base.js中

  // 请求超时时间
  timeout: 60 * 1000,
  // 跨域请求时是否需要凭证
  // withCredentials: true, // Check cross-site Access-Control
  heards: {
    get: {
      // 设置默认请求头，当需要特殊请求头时，将其作为参数传入，即可覆盖此处的默认参数
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      // 设置默认请求头，当需要特殊请求头时，将其作为参数传入，即可覆盖此处的默认参数(第三个参数即config)
      // 例如：
      //     services.post(`${base.lkBaseURL}/uploads/singleFileUpload`, file, {
      //       headers: { "Content-Type": "multipart/form-data" }
      //     });
      'Content-Type': 'application/json;charset=utf-8'
    }
  }
  // 在向服务器发送请求前，对数据进行处理，axios默认会序列化数据
  // transformRequest:[function(data){
  //
  // }],
  // 在传递给 then/catch 前，修改响应数据
  // transformResponse:[function(data){
  //
  // }]
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    // 从vuex里获取token
    const token = store.state.token
    // 如果token存在就在请求头里添加
    token && (config.headers.Authorization = token)
    // if (config.hideMessage)
    return config
  },
  (error) => {
    // Do something with request error
    error.data = {}
    error.successed = false
    error.data.msg = '服务器异常'
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.status === 200) {
      console.log("res",res.data.status)
      if (res.data.status != 200) {
        if (!res.config.hideMessage) {
          // 不需要错误提示语，则不执行
          errorHandle(res.data.status, res.data.msg)
        }
        return Promise.reject(res.data)
      }
      return Promise.resolve(res)
    } else {
      errorHandle(res.status, res.data.message)
      return Promise.reject(res)
    }
  },
  // 请求失败
  (error) => {
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        // store.commit('changeNetwork', false)
      } else {
        return Promise.reject(error)
      }
    }
  }
)

export default instance
