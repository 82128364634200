const getBase64 = function(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

import router from '../router'
const handleHeadBack = function() {
  router.go(-1)
}

const jump = function(e) {
  let value = {
    path: e.path
  }
  if (e.query) {
    value.query = e.query
  }
  router.push(value)
}

const getPageTitle = function(e = 0) {
  const route = this.$route
  return route.meta.current[e]
}

export default {
  install(Vue) {
    Vue.prototype.$getBase64 = getBase64
    Vue.prototype.$handleHeadBack = handleHeadBack
    Vue.prototype.$jump = jump
    Vue.prototype.$getPageTitle = getPageTitle
  }
}
